import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { ConfigApi, Helpers } from '../service/index';
import { Link } from 'react-router-dom';
import DeleteConfirmComponent from '../components/DeleteConfirmComponent';
import CircularProgress from '@mui/material/CircularProgress';
import '../css/style.css';
import { useEffect, useState } from 'react';
const drawerWidth = 240;

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();
const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);
export default function Location() {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(true);
  const [errors, setErrors] = useState({ 'key': false, 'message': '' });
  const [locationData, setLocationData] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeleteId, setDeleteId] = useState(0);
  const [countryData, setcountryData] = useState([]);
  const [stateData, setstateData] = useState([]);
  const [cityData, setcityData] = useState([]);

  const handleDeleteClick = (id) => {
    setDeleteId(id);
    setIsDeleteModalOpen(true);
  };

  const handleConfirmDelete = () => {
    deleteLocation();

  };

  const handleCancelDelete = () => {
    setIsDeleteModalOpen(false);
  };
  const deleteLocation = () => {
    const formData = new FormData();
    ConfigApi.deleteLocation(isDeleteId).then(
      (resp) => {
        if (resp.status === 200) {
          setIsDeleteModalOpen(false);
          getLocation();
        } else {
          console.log("error", resp.data.error_msg);
        }
      }
    ).catch((error) => {
      Helpers.error401(error);
    });
  };
  const toggleDrawer = () => {
    setOpen(!open);
  };
  useEffect(() => {
    getLocation();
    getcountry();
    getstate();
    getcity();
  }, []);

  const getLocation = () => {
    const formData = new FormData();
    setLoading(true);
    ConfigApi.location({}).then(
      (resp) => {
        if (resp.status === 200) {
          setLoading(false);
          setLocationData(resp.data.response);
          setIsDeleteModalOpen(false);
        } else {
          console.log("error", resp.data.error_msg);
        }
      }
    ).catch((error) => {
      setLoading(false);
      Helpers.error401(error);
    });
  };

  const getcountry = () => {
    const formData = new FormData();
    setLoading(true);
    ConfigApi.getcountry({}).then(
      (resp) => {
        if (resp.status === 200) {
          setLoading(false);
          setcountryData(resp.data.response);
          setIsDeleteModalOpen(false);
        } else {
          console.log("error", resp.data.error_msg);
        }
      }
    ).catch((error) => {
      setLoading(false);
      Helpers.error401(error);
    });
  };

  const getstate = () => {
    const formData = new FormData();
    setLoading(true);
    ConfigApi.getstate({}).then(
      (resp) => {
        if (resp.status === 200) {
          setLoading(false);
          setstateData(resp.data.response);
          setIsDeleteModalOpen(false);
        } else {
          console.log("error", resp.data.error_msg);
        }
      }
    ).catch((error) => {
      setLoading(false);
      Helpers.error401(error);
    });
  };

  const getcity = () => {
    const formData = new FormData();
    setLoading(true);
    ConfigApi.getcity({}).then(
      (resp) => {
        if (resp.status === 200) {
          setLoading(false);
          setcityData(resp.data.response);
          setIsDeleteModalOpen(false);
        } else {
          console.log("error", resp.data.error_msg);
        }
      }
    ).catch((error) => {
      setLoading(false);
      Helpers.error401(error);
    });
  };

  return (
    <div style={{ "padding": "0 30px", }}>

      <Typography className={Helpers.cmAccess('LOCATION_ADD')} variant="h5" component="div" style={{ "fontFamily": "'Montserrat', sans-serif", "fontSize": "24px", "fontWeight": "600", "marginBottom": "27px" }}>
        Location <Link to="/dashboard/location/addlocation" style={{ float: "right", "fontSize": "14px", "textDecoration": "none", "color": "#000", "padding": "7px 16px", "border": "1px solid #d7d7d7", "marginTop": "8px", background: "#fff" }}>Add Location </Link>
      </Typography>
      <Grid container spacing={2}  columns={{ xs: 4, md: 12 }}>
        {locationData.map((content, index) => (
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <Card sx={{ minWidth: 275 }} className="locationborder">

              <CardContent>
                <img src="/loca.png" className="shifticons" />


                <Typography variant="h6" component="div" style={{ "fontFamily": "'Montserrat', sans-serif", "fontSize": "14px" }}>
                  <i className="bi bi-geo-alt"></i> {content.name}
                </Typography>
                <div className="btnloc">

                <div className={Helpers.cmAccess('LOCATION_EDIT')}>  
                  <Link to={`/dashboard/location/addlocation/${content.office_location_id}`} style={{ "padding": "7px 0", "fontSize": "14px", "fontFamily": "Montserrat", "marginRight": "11px", "color": "#3ec312", "textDecoration": "none", "fontWeight": "600", cursor: "pointer" }}><i class="bi bi-pencil-square"></i> Edit Location </Link>
                  </div>
                  <div className={Helpers.cmAccess('LOCATION_DELETE')}>
                  <button onClick={() => handleDeleteClick(content.office_location_id)} style={{ "padding": "7px 0", "background": "none", "border": "none", "fontSize": "14px", "fontFamily": "'Montserrat'", "color": "#d30c0c", "fontWeight": "600", cursor: "pointer" }}><i class="bi bi-trash3"></i> Delete</button>
                  </div>

                </div>



              </CardContent>

            </Card>
          </Grid>
        ))}


        <DeleteConfirmComponent
          isOpen={isDeleteModalOpen}
          onConfirm={handleConfirmDelete}
          onCancel={handleCancelDelete}
        />
        <div className='loader'>
          {loading ? (
            <CircularProgress style={{color:'#ED9F2A'}}/>
          ) : ''}
        </div>
      </Grid>
    </div>
  );
}