import * as React from 'react';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { ConfigApi, Helpers } from '../service/index';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));


export default function Access() {
  const [access, setAccess] = React.useState([]);
  const [roleid, setRoleId] = useState(0);
  const [components, setComponents] = useState([]);

  let { id } = useParams();
  const handleAccessChange = (id, value) => {

    const updatedComponents = components.map((item) => {
      if (id == "ALL") {
        return { ...item, access: value };
      }
      else {
        if (item.id === id) {
          return { ...item, access: value };
        }

      }
      return item;
    });

    setComponents(updatedComponents);
  };

  useEffect(() => {
    if (id) {
      setRoleId(id);
      getComponent(id);
    }
  }, []);

  const getComponent = (id) => {
    ConfigApi.getComponent(id).then(
      (resp) => {
        if (resp.status === 200) {
          setAccess(resp.data.data.access);
          setComponents(resp.data.data.component);
          let loggedUser = Helpers.loggedUserInfo();
          if (id == loggedUser.role_id) {
            Helpers.createLSByName('components', resp.data.data);
          }

        } else {
          console.log("error", resp.data.error_msg);
        }
      }
    ).catch((error) => {
      Helpers.error401(error);
    });
  };
  const submitAccess = () => {
    const formData = new FormData();
    formData.append("components", JSON.stringify(components));
    ConfigApi.createUpdateRoleAccess(id, formData).then(
      (resp) => {
        if (resp.status === 200) {
          Helpers.toastSuccess('Access updated')
          getComponent(roleid);

        } else {
          console.log("error", resp.data.error_msg);
        }
      }
    ).catch((error) => {
      Helpers.error401(error);
    });
  };

  return (
    <div style={{ width: '100%' }}>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12}> 
          <Item style={{backgroundColor:"none", boxShadow:"none", padding:0,"margin":" 0 0 0 30px"}}>
            <TableContainer component={Paper} style={{ padding: 20 }}>
              <Table md={{ Width: 500 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontSize: "15px", fontWeight: "bold" }}>Component Name</TableCell>
                    <TableCell>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          onChange={(e) => handleAccessChange("ALL", e.target.value)}
                        >
                          <FormLabel id="demo-controlled-radio-buttons-group" style={{ marginTop: 10, marginRight: 5, fontWeight: 'bold' }}>Access</FormLabel>
                          <FormControlLabel value="READ_WRITE" control={<Radio />} label="Read Write" />
                          <FormControlLabel value="READ_ONLY" control={<Radio />} label="Read Only" />
                          <FormControlLabel value="HIDDEN" control={<Radio />} label="Hidden" />
                        </RadioGroup>
                      </FormControl>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {components.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell key={`cell-${index}`}>{row.component_label}</TableCell>
                      <TableCell>
                        <FormControl>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={row.access}
                            onChange={(e) => handleAccessChange(row.id, e.target.value)}
                            key={`RadioGroup-${index}`}
                          >

                            <FormControlLabel value="READ_WRITE" checked={row.access == "READ_WRITE" ? true : false} control={<Radio />} label={access.READ_WRITE} />
                            <FormControlLabel value="READ_ONLY" checked={row.access == "READ_ONLY" ? true : false} control={<Radio />} label={access.READ_ONLY} />
                            <FormControlLabel value="HIDDEN" checked={row.access == "HIDDEN" ? true : false} control={<Radio />} label={access.HIDDEN} />
                          </RadioGroup>
                        </FormControl>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <Grid item xs={12} style={{ display: "flex", justifyContent: "end" }}>
                <button type="button" variant="contained" onClick={(e) => submitAccess()} className="btnorange btnorange">Submit</button>

              </Grid>
            </TableContainer>
          </Item>
        </Grid>
      </Grid>
    </div>
  );
}
