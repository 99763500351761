import React, { useEffect, useState } from 'react';
import { styled } from '@mui/system'; // Updated import statement
import Table from '@mui/material/Table';
import Typography from '@mui/material/Typography';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { ConfigApi, UserApi, Helpers } from '../service/index';

// Define the styles using the styled function
const StyledTableCell = styled(TableCell)({
  fontWeight: 'bold',
  // Add other styling properties as needed, e.g., paddingRight, paddingLeft
});

const Notificationlist = () => {
  const [notificationData, setNotificationData] = useState([]);
  useEffect(() => {
    getEventHistory();
  }, []);

  const getEventHistory = async () => {
      await UserApi.getEventHistory().then(
          (resp) => {
              if (resp.status === 200) {
                console.log(resp.data.unread_count);
                setNotificationData(resp.data.eventHistory);
              } else {
                  console.log("error", resp.data.error_msg);
                  alert(resp.data.error_msg);
              }
          }
      ).catch((error) => {
          /* alert(error);*/
          Helpers.error401(error);
      });
  };

  const formatNotificationType = (type) => {
    // Split the type string by underscores
    const words = type.split('_');
  
    // Capitalize the first letter of each word
    const capitalizedWords = words.map(word =>
      word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    );
  
    // Join the words with spaces
    return capitalizedWords.join(' ');
  };

  return (
    <div className="tableUser" style={{marginLeft:20,}}>
      <div>
     

      <Grid container spacing={2}>
  <Grid item xs={6}>
  <Typography variant="h5" mx={2} component="h2" style={{ "fontFamily": "'Montserrat', sans-serif", "fontSize": "20px", "fontWeight": "600", marginBottom: "19px", }}>
  Notification List
</Typography>
  </Grid>
  <Grid item xs={6}>
  <div style={{marginLeft:20,textAlign:"right"}}><Button variant="outlined" style={{borderColor:"#f60"}}><Link className="colrolink" style={{textDecoration:"none",color:"f60 !important", fontFamily:"Montserrat, sans-serif", fontSize:"14px", }} href="/dashboard/register"><i class="bi bi-plus-lg"></i> Add Employee</Link></Button></div>
  
  </Grid>
</Grid>
</div>
      <TableContainer component={Paper} sx={{ overflowX: 'auto'}}>
        <Table sx={{ minWidth: 650 }} aria-label="User List Table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Notification Name</StyledTableCell>
              <StyledTableCell>Notification Message</StyledTableCell>
              <StyledTableCell>Notification Time</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {notificationData.length > 0 && notificationData.map((notification) => (
              <TableRow key={notification.id}>
                <TableCell>{notification.event_name ? formatNotificationType(notification.event_name) : 'Unknown'}</TableCell>
                <TableCell>{notification.event_message}</TableCell>
                <TableCell>{notification.event_time}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Notificationlist;
