import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';

const drawerWidth = 240;

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();
const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);
export default function Config() {
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <div style={{background:'#fff',"padding":"0 30px","borderRadius":"10px"}}>
      
      <Typography variant="h5" component="div" style={{"fontFamily":"'Montserrat', sans-serif","fontSize":"24px","fontWeight":"600","marginBottom":"27px"}}>
          Config
        </Typography>
        <Grid container spacing={2}>
  <Grid item xs={4}>
  <Card sx={{ minWidth: 275 }} style={{"boxShadow":"none","border":"1px solid #e7e7e7","borderRadius":"16px"}}>
    <img src="/wired-flat-45-clock-time.gif" style={{width:"103px"}} />
      <CardContent>
      
        <Typography variant="h5" component="div" style={{"fontFamily":"'Montserrat', sans-serif"}}>
          Shifts
        </Typography>
      
        <Typography variant="body2" style={{"fontFamily":"'Montserrat', sans-serif"}}>
           Timing of Company Employe Select
         
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" style={{"fontFamily":"'Montserrat', sans-serif"}}>Click Here</Button>
      </CardActions>
    </Card>
  </Grid>
  <Grid item xs={4}>
  <Card sx={{ minWidth: 275 }} style={{"boxShadow":"none","border":"1px solid #e7e7e7","borderRadius":"16px"}}>

    <img src="/wired-lineal-18-location-pin.gif" style={{width:"103px"}} />
      <CardContent>
      
        <Typography variant="h5" component="div" style={{"fontFamily":"'Montserrat', sans-serif"}}>
          Location
        </Typography>
      
        <Typography variant="body2" style={{"fontFamily":"'Montserrat', sans-serif"}}>
           Select Location of Company
         
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" style={{"fontFamily":"'Montserrat', sans-serif"}}>Click Here</Button>
      </CardActions>
    </Card>
  </Grid>



</Grid>
      </div>
  );
}