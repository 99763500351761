import { ConfigApi } from '../../service/index';
import React from 'react';
import { toast } from 'react-toastify';
import Papa from 'papaparse';

class Helper {
    
	
    loggedUserInfo() {
	    let userInfo = "";
		if(localStorage.getItem('userInfo'))
		{
			userInfo = JSON.parse(localStorage.getItem('userInfo'));
		}
		return userInfo
    }
	
	setAdminAccess(roles) {
	    let loggedUser = this.loggedUserInfo();
		let access = 'HIDDEN';
		if (roles.includes(loggedUser.role.role_key)) {
			access = "READ_WRITE";
		}
		
		return access;
    }
	
	getLSByName(lsName) {
	    let info = "";
		if(localStorage.getItem(lsName))
		{
			info = JSON.parse(localStorage.getItem(lsName));
		}
		return info;
    }
	
	createLSByName(lsName,value) {
	    let info = "";
		if(value)
		{
			info = localStorage.setItem(lsName,JSON.stringify(value));
		}
		return info
    }
	
	checkLogin() {
		
		let response = true;
	    let userInfo = this.getLSByName('userInfo');
	    let token = this.getLSByName('token');
		if(!userInfo || !token)
		{
			response = false;
		}
		return response
    }
	
	logout(history) {
		
		let institution_id = this.getLSByName('institution_id');
		this.logoutApi();
	    localStorage.clear();
		if(institution_id)
		{
			this.createLSByName("institution_id",institution_id);
		}
	    
	    window.location.href="/";
    }
	
	logoutApi(){
        ConfigApi.postApiCall('logout?islogout=true',{}).then(
            (resp) => {
                
                    console.log("error", resp.data.error_msg);
                
            }
        ).catch((error) => {
            if (error.response) {
				console.log(error.response);
			}
        });
    };

	getComponent(id,key,history){
        ConfigApi.getComponent(id).then(
            (resp) => {
                if (resp.status === 200) {
                    this.createLSByName('components',resp.data.data);
					//history('/dashboard/app');
					if(key=="END_USER"){
                      window.location.href="/dashboard/profile";
				    }else{
						window.location.href="/dashboard/app";
					}
                    
                } else {
                    console.log("error", resp.data.error_msg);
                }
            }
        ).catch((error) => {
            if (error.response) {
				console.log(error.response);
			}
        });
    };
	
	cmAccess(key)
	{
		const components = this.getLSByName('components');
		let access = 'HIDDEN';
		if (components) {
		  for (const item of components.component) {
			if (item.component_key === key) {
			  access = item.access ? item.access : 'HIDDEN';
			  break;
			}
		  }
		}

		return access;
	}
	
	error401(error)
	{
		if (error.response) {
			if(error.response.status == 401)
			{
				this.toastError("Logout");
				
				setTimeout(() => {
				  this.logout("Token expairy");
				}, 2000);
			}
			else if(error.response.status == 400)
			{
				this.toastError(error.response.data.error_msg);
			}
		}
	}
	
	toastSuccess(msg)
	{
		toast.success(msg);
	}
	toastError(msg)
	{
		toast.error(msg);
	}
	minutesToHours(minutes) {
	  const hours = Math.floor(minutes / 60);
	  const remainingMinutes = minutes % 60;

	  return `${hours}.${remainingMinutes}`;
	}
	generateCsv(data) {
	  const csv = Papa.unparse(data);
	  return csv;
	}
	
	downloadCsv(csv, filename) {
	  const blob = new Blob([csv], { type: 'text/csv' });
	  const url = window.URL.createObjectURL(blob);
	  const a = document.createElement('a');
	  a.style.display = 'none';
	  a.href = url;
	  a.download = filename;
	  document.body.appendChild(a);
	  a.click();
	  window.URL.revokeObjectURL(url);
	}

	

	getControlType()
	{
		return {
			TextField: 'TextField',
			TextArea: 'TextArea',
			DropDown: 'DropDown',
			CheckBox: 'CheckBox',
			RadioButton: 'RadioButton',
			Information: 'Information',
			Range: 'Range',
			MultipleTextbox: 'MultipleTextbox',
			Date: 'Date',
			RadioButton_Numerical: 'RadioButton_Numerical',
		  };
	}
	
}

export default Helper;