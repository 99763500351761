import React, { useState, useEffect } from "react";
import { ConfigApi, Helpers } from '../service/index';
import { useParams } from 'react-router-dom';
import { createTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import '../css/style.css';


const drawerWidth = 240;

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

<Box
  component="span"
  sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
>
  •
</Box>



export default function AddRole() {
  const [open, setOpen] = React.useState(true);
  const [role_name, setrole_name] = useState('');
  //const [submitted, setSubmitted] = useState(false);
  const [errors, setErrors] = useState({});
  const [ids, setIds] = useState(0);

  let { id } = useParams();

  const toggleDrawer = () => {
    setOpen(!open);
  };
  useEffect(() => {

    if (id) {
      setIds(id);
      editRole(id);
    }
  }, []);
  function isJsonString(str) {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  }
  const handleSubmit = (event) => {
    try {
      const loggedUser = Helpers.loggedUserInfo();
      event.preventDefault();
      setErrors({});
      const data = new FormData(event.currentTarget);
      var error = {};
      if (!data.get('role_name')) {
        error = { "role_name": "Role name required", "isError": true };
      }
      if (error.isError) {
        throw new Error(JSON.stringify(error));
      }
      var dataInfo = {
        "role_name": role_name
      };
      ids ? updateRole(JSON.stringify(dataInfo)) : addRole(JSON.stringify(dataInfo));

    } catch (error) {
      let errorData = { "isError": true, "common": error.message };
      if (isJsonString(error.message)) {
        errorData = JSON.parse(error.message);
      }

      setErrors(errorData);
    }
  };

  const addRole = (formData) => {

    ConfigApi.addRole(formData).then(
      (resp) => {
        if (resp.status === 200) {
          Helpers.toastSuccess('Role created successfully');
          window.location.href = "/dashboard/Role";
        } else {
          console.log("error", resp.data.error_msg);
        }
      }
    ).catch((error) => {
      Helpers.error401(error);
    });
  };

  const updateRole = (formData) => {

    ConfigApi.updateRole(formData, ids).then(
      (resp) => {
        if (resp.status === 200) {
          Helpers.toastSuccess('Role updated successfully');
          window.location.href = "/dashboard/Role";
        } else {
          console.log("error", resp.data.error_msg);
        }
      }
    ).catch((error) => {
      Helpers.error401(error);
    });
  };

  const editRole = (id) => {

    ConfigApi.editRole(id).then(
      (resp) => {
        if (resp.status === 200) {
          const editInfo = resp.data.response;
          setrole_name(editInfo.role_name);

        } else {
          console.log("error", resp.data.error_msg);
        }
      }
    ).catch((error) => {
      Helpers.error401(error);
    });
  };

  return (
    <div style={{ background: '#fff', "margin": "0px 0px 0 30px", "borderRadius": "10px" }}>
      <div className="bgwhite2">
      <Typography variant="h5" component="div" style={{ "fontFamily": "'Montserrat', sans-serif", "fontSize": "24px", "fontWeight": "600", "marginBottom": "27px" }}>
        Add Roles
      </Typography>
      <form onSubmit={handleSubmit} className="roleForm">

        <Grid container spacing={1} marginTop={7} >

          <Grid item xs={12} lg={3}>
            <p className="labelname">Role Name:</p>

            <input type="text" name="role_name" value={role_name} onChange={(e) => setrole_name(e.target.value)} className="form-control" />
            <div className="rederror">{errors?.role_name}</div>
          </Grid>

          <Grid className={Helpers.cmAccess('ROLE_EDIT_SUBMIT')} item xs={12} lg={3}>
            <button type="submit" variant="contained" className="btnorange btnorange">Submit</button>

          </Grid>


        </Grid>
      </form>
</div>

    </div>
  );
}




