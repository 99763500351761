import React, { useState, useEffect } from "react";
import { ConfigApi, Helpers, UserApi } from '../service/index';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import '../css/style.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { format } from 'date-fns';


// TODO remove, this demo shouldn't need to reset the theme.
export default function Reports() {
  const [open, setOpen] = React.useState(true);
  const [rows, setRows] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const [location, setLocation] = useState(0);
  const [userListData, setUserListData] = useState([]);
  const [currentUser, setCurrentUser] = useState(0);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [links, setLinks] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);



  const toggleDrawer = () => {
    setOpen(!open);
  };

  const pagination = (url) => {
    if (url) {
      ConfigApi.paginationApiCall(url).then(
        (resp) => {
          if (resp.status === 200) {
            setRows(resp.data.data.data);
            setLinks(resp.data.data.links);
            setCurrentPage(resp.data.data.current_page > 1 ? 10 * (resp.data.data.current_page - 1) : 0);

          } else {
            console.log("error", resp.data.error_msg);
          }
        }
      ).catch((error) => {
        Helpers.error401(error);
      });
    }
  };
  useEffect(() => {
    getLocation();
    let loggedUser = Helpers.loggedUserInfo();
    if (loggedUser) {
      setLocation(loggedUser.office_location);
      getUserList(loggedUser.office_location);
    }
  }, []);
  const getLocation = () => {
    ConfigApi.location().then(
      (resp) => {
        if (resp.status === 200) {
          setLocationData(resp.data.response);
        } else {
          console.log("error", resp.data.error_msg);
          alert(resp.data.error_msg);
        }
      }
    ).catch((error) => {
      Helpers.error401(error);
    });
  };

  const changeLocation = (id) => {
    setLocation(id);
    setCurrentUser();
    getUserList(id);
  }

  const getUserList = (id) => {
    UserApi.userlistwithLocationId(id).then(
      (resp) => {
        if (resp.status === 200) {
          setUserListData(resp.data.response);

        } else {
          console.log("error", resp.data.error_msg);
          alert(resp.data.error_msg);
        }
      }
    ).catch((error) => {
      //alert(error);
      Helpers.error401(error);
    });
  };

  const attendanceReport = (download = null) => {
    setRows([]);
    setCurrentPage(1);
    debugger;
    let params = "";
    if (currentUser) {
      params += "&user_id=" + currentUser;
    }

    if (fromDate) {
      params += "&fromDate=" + fromDate;
    }

    if (toDate) {
      params += "&toDate=" + toDate;
    }
    if (download) {
      params += "&download=" + true;
    }

    ConfigApi.attendanceReport(location, params).then(
      (resp) => {
        if (resp.status === 200) {

          if (download) {
            let csvData = Helpers.generateCsv(resp.data.data);
            Helpers.downloadCsv(csvData, 'attendanceReport.csv');
          }
          else {
            setRows(resp.data.data.data);
            setLinks(resp.data.data.links);
          }


        } else {
          console.log("error", resp.data.error_msg);
        }
      }
    ).catch((error) => {
      Helpers.error401(error);
    });
  };

  return (
    <div style={{ "padding": "0 30px", }}>

      <Grid container spacing={1} className="marginalign">

        <Grid item xs={12} lg={2}>

          <Typography variant="h5" component="div" className="typomain">
            Reports
          </Typography>

        </Grid>

        <Grid item xs={12} lg={2}>
          <div>
            <label className="labelname0p" forhtml="Location">Location</label>

            <select className="form-control" name="location" value={location} onChange={(e) => changeLocation(e.target.value)} >
              {
                locationData.length > 0 && locationData?.map((item, index) => (
                  <option key={`location-${index}`} value={item.office_location_id} >{item.name}</option>

                ))
              }

            </select>
          </div>
        </Grid>
        <Grid item xs={12} lg={2}>
          <div>
            <label className="labelname0p" forhtml="Location">Users</label>

            <select className="form-control" name="currentUser" value={currentUser} onChange={(e) => setCurrentUser(e.target.value)} >
              <option value="">Select User</option>
              {userListData.map((item, index) => (
                <option value={item.id} key={`currentUserss-${item.id}`}>
                  {`${item.first_name} ${item.last_name} ( ${item.user_code} )`}
                </option>
              ))}
            </select>
          </div>
        </Grid>

        <Grid item xs={12} lg={2}>
          <div>
            <label className="labelname0p" forhtml="Start Date">Start Date</label>
            <input type="date" id="Start-Date" name="Start Date" className="form-control" value={fromDate} onChange={(e) => setFromDate(e.target.value)} />
          </div>
        </Grid>
        <Grid item xs={12} lg={2}>
          <div>
            <label className="labelname0p" forhtml="End Date">End Date</label>
            <input type="date" id="End-Date" name="End Date" className="form-control" value={toDate} onChange={(e) => setToDate(e.target.value)} />
          </div>
        </Grid>

        <Grid className={Helpers.cmAccess('REPORT_SEARCH')} item xs={6}  lg={1}>
          <Button variant="outlined" className="btnfinal" onClick={(e) =>  attendanceReport()}><i className="bi bi-search"  ></i></Button>
          </Grid>
		  <Grid className={Helpers.cmAccess('REPORT_DOWNLOAD')} item xs={6}  lg={1}>
		  <Button variant="outlined" className="btnfinal"  onClick={(e) =>  attendanceReport('download')}><i className="bi bi-download"></i></Button>
		   </Grid>
      </Grid>
<div>
<TableContainer className={Helpers.cmAccess('REPORT_TABLE')} component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Location</TableCell>
                <TableCell>User Name</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Check In</TableCell>
                <TableCell>Check Out</TableCell>
                <TableCell>Working Hours</TableCell>
                <TableCell>Replace to</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow
                  key={row.name}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell scope="row" key={`id${currentPage + index + 1}`}>{ currentPage===1?currentPage + index +0:currentPage + index +1}</TableCell>
                  <TableCell key={`id${row.name}${index}`}>{row.name}</TableCell>
                  <TableCell key={`location${row.first_name}${index}`}>{row.first_name} {row.last_name}</TableCell>
                  <TableCell key={`userName${row.current_date}${index}`}>{row?.current_date ? format(new Date(row.current_date), 'dd-MM-yyyy') : ''}</TableCell>
                  <TableCell key={`date${row.time_in}${index}`}>{row?.time_in ? format(new Date(row.time_in), 'hh:mm a') : ''}</TableCell>
                  <TableCell key={`checkOut${row.time_out}${index}`}>{row?.time_out ? format(new Date(row.time_out), 'hh:mm a') : ''}</TableCell>
                  <TableCell key={`workingHours${row.total_minutes}${index}`}>{Helpers.minutesToHours(row.total_minutes)}</TableCell>
                  <TableCell key={`replaceTo${row.rep_last_name}${index}`} >{row.rep_first_name} {row.rep_last_name}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <div className="pagination">

            {links.length > 0 && links?.map((item, index) => (
              <button key={`btn-${index}`} className={item.active ? 'active btn-primary' : ' btn-primary'} onClick={(e) => pagination(item.url)}  >  <span dangerouslySetInnerHTML={{ __html: item.label }} /></button>

            ))}
          </div>

        </TableContainer>

      </div>

    </div>
  );
}




