import AuthService from "./auth/auth";
import ConfigService from "./auth/config";
import UserService from "./auth/user";
import Helper from "./auth/Helper";

const AuthApi = new AuthService();
const ConfigApi = new ConfigService();
const UserApi = new UserService();
const Helpers = new Helper();

export {AuthApi,ConfigApi,UserApi,Helpers}