import * as React from 'react';
import { useEffect, useState } from 'react';
import { createTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { ConfigApi, Helpers } from '../service/index';
import { Link } from 'react-router-dom';
import DeleteConfirmComponent from '../components/DeleteConfirmComponent';
import CircularProgress from '@mui/material/CircularProgress';
import '../css/style.css';
const drawerWidth = 240;

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();
const bull = (
    <Box
        component="span"
        sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
    >
        •
    </Box>
);
export default function Role() {
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = React.useState(true);
    const [errors, setErrors] = useState({ 'key': false, 'message': '' });
    const [RoleData, setRoleData] = useState([]);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isDeleteId, setDeleteId] = useState(0);

    const handleDeleteClick = (id) => {
        setDeleteId(id);
        setIsDeleteModalOpen(true);
    };

    const handleConfirmDelete = () => {
        deleteRole();

    };

    const handleCancelDelete = () => {
        setIsDeleteModalOpen(false);
    };

    const toggleDrawer = () => {
        setOpen(!open);
    };
    useEffect(() => {
        getRole();
    }, []);

    const getRole = () => {
        const formData = new FormData();
        formData.append("location_name", 'SmartCRM');
        setLoading(true);
        ConfigApi.role(formData).then(
            (resp) => {
                if (resp.status === 200) {
                    setLoading(false);
                    setRoleData(resp.data.response);
                    setIsDeleteModalOpen(false);
                } else {
                    console.log("error", resp.data.error_msg);
                }
            }
        ).catch((error) => {
            setLoading(false);
            Helpers.error401(error);
        });
    };

    const deleteRole = () => {
        const formData = new FormData();
        formData.append("isDeleteId", isDeleteId);
        ConfigApi.deleteRole(isDeleteId).then(
            (resp) => {
                if (resp.status === 200) {
                    getRole();
                } else {
                    console.log("error", resp.data.error_msg);
                }
            }
        ).catch((error) => {
            Helpers.error401(error);
        });
    };

    try {
    } catch (error) {
        console.log(error);
        const errorData = JSON.parse(error.message);
        setErrors({ 'key': errorData.key, 'message': errorData.message });
    }

    return (
        <div style={{ "padding": "0 30px", }}>
            <div>
                <Typography className={Helpers.cmAccess('ROLE_ADD')} variant="h5" component="div" style={{ "fontFamily": "'Montserrat', sans-serif", "fontSize": "24px", "fontWeight": "600", "marginBottom": "27px" }}>
                    Role<Link to="/dashboard/role/addRole" className='portallink'>  Add Role </Link>

                </Typography>
                <Grid container spacing={2}>
                    {RoleData.map((content, index) => (
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <Card className="locationborder">

                                <CardContent>
                                    <img src="/smartphone.png" className="shifticons" />

                                    <Typography variant="h6" component="div" style={{ "fontFamily": "'Montserrat', sans-serif", "fontSize": "14px", "borderBottom": "1px solid #e1e1e1", "marginBottom": "12px", "paddingBottom": "9px" }}>
                                        <i className="bi bi-person-square"></i>  {(content.role_name)}
                                    </Typography>

                                    {content.isaccess == 0 ? <div className={Helpers.cmAccess('ROLE_EDIT')}>
                                        <Link to={`/dashboard/role/addRole/${content.id}`} className="editrole"><i class="bi bi-pencil-square"></i> Edit Role </Link> &nbsp; &nbsp;
                                    </div>
                                    :
                                    <div className={Helpers.cmAccess('ROLE_EDIT')}>
                                    <button className="editrole disabled" disabled><i class="bi bi-pencil-square"></i> Edit Role </button> &nbsp; &nbsp;
                                    </div>}

                                    {content.isaccess == 0 ? <div className={Helpers.cmAccess('ROLE_DELETE')}>
                                        <button onClick={() => handleDeleteClick(content.id)} className="deleterole " ><i class="bi bi-trash3"></i> Delete</button>
                                    </div>
                                    :
                                    <div className={Helpers.cmAccess('ROLE_DELETE')}>
                                    <button className="deleterole disabled" disabled><i class="bi bi-trash3"></i> Delete</button>
                                </div>}
                                    <div className={Helpers.cmAccess('ROLE_ACCESS')}>
                                        <Link to={`/dashboard/role/access/${content.id}`} className="editrole marginalign"><i class="bi bi-pencil-square"></i> Access </Link>
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}

                    <DeleteConfirmComponent
                        isOpen={isDeleteModalOpen}
                        onConfirm={handleConfirmDelete}
                        onCancel={handleCancelDelete}
                    />
                    <div className='loader'>
                        {loading ? (
                            <CircularProgress style={{color:'#ED9F2A'}}/>
                        ) : ''}
                    </div>
                </Grid>
            </div>
        </div>
    );
}