import React, { useState, useEffect } from "react";
import { ConfigApi, Helpers, UserApi } from '../service/index';
import Typography from '@mui/material/Typography';
import { useLocation,useNavigate } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import '../css/style.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { NavLink } from 'react-router-dom';
import { format } from 'date-fns';
import CircularProgress from '@mui/material/CircularProgress';

// TODO remove, this demo shouldn't need to reset the theme.
export default function MainPages() {
  const [rows, setRows] = useState([]);
  const [currentUser, setCurrentUser] = useState(0);
  const [getPagesMenu, setPagesMenu] = useState([]);
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };




  const callAPI = (url,type) => {
    setLoading(true);
    if (url) {
      ConfigApi.paginationApiCall(url).then(
        (resp) => {
          if (resp.status === 200) {
			  if('getPagesMenu' === type)
			  {
            if(resp.data.data.length > 0)
            {
            let menuPage = resp?.data?.data[0]?.pageConfigDetail;
            if(menuPage.length > 0)
            {
              navigate('/dashboard/page/'+menuPage[0].pageConfigDetailId, { replace: true});
            }
          }
			  }
         setLoading(false);
          } else {
            setLoading(false);
            console.log("error", resp.data.error_msg);
          }
        }
      ).catch((error) => {
        setLoading(false);
        Helpers.error401(error);
      });
    }
  };
  useEffect(() => {
    let loggedUser = Helpers.loggedUserInfo();
    callAPI("/getPagesMenu?entityId="+loggedUser.id,'getPagesMenu');
  }, []);
  
  
  const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
		 
          <Typography component="div">{children}</Typography>
		 
        </Box>
      )}
    </div>
  );
};

  return (
    <div style={{ background: '#fff', "padding": "0 30px", "borderRadius": "10px" }}>
		 <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        left
      >
	    {getPagesMenu.map((row, index) => (
			<Tab label={row.pageName} />
        ))}
       
      </Tabs>
	{getPagesMenu.map((row, index) => (
     <TabPanel value={value} index={index} key={`TabPanel${index}`}>
	
	   {row.pageConfigDetail.map((pageConfigDetail, index) => (
	    
		<div className="typoh6">
		  <Typography variant="h6" key={`pageConfigDetail${index}`} className={Helpers.cmAccess('MENU_DASHBOARD')}>
		  <NavLink className="ankerlink"  key={`pageConfig${index}`} to={`/dashboard/mainPageResponses/${pageConfigDetail.pageConfigDetailId}`} >{pageConfigDetail.pageQuestion}</NavLink>
		  </Typography>
      </div>
		
		 ))}
		 
      </TabPanel>
	 ))}
  <div className='loader'>
          {loading ? (
            <CircularProgress style={{color:'#ED9F2A'}}/>
          ) : ''}
        </div>
    </div>
  );
}




