// routes
import Router from './routes';
// components

// ----------------------------------------------------------------------

export default function App() {

  return (
      <Router />
  );
}
