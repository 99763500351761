import * as React from 'react';
import  { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Link } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useState } from 'react';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { AuthApi,Helpers } from '../service/index'; 
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import '../css/style.css';



function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="#">
        Smart Attendance
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function SignInSide() {
	
	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState({'key':false,'message':''});
	const history = useNavigate();
  let institution_id = null;
  useEffect(() => {
    institution_id = Helpers.getLSByName('institution_id');
    if(!institution_id)
    {
      history('/searchCompany', { replace: true})
    }
  }, []);

    const handleSubmit = (event) => {
	  setErrors({'key':'','message':''});	
    event.preventDefault();
    try{ 
    const data = new FormData(event.currentTarget);
	
      const formData = new FormData();
	  if(!data.get('email'))
	  {
		throw new Error(JSON.stringify({'key':'email','message':'email required'}));
	  }
	  
	  if(!data.get('password'))
	  {
		throw new Error(JSON.stringify({'key':'password','message':'password required'}));
	  }
	  
      formData.append("email", data.get('email'));
      formData.append("institution_id", institution_id);
      formData.append("password", btoa(data.get('password')));
      setLoading(true);
      AuthApi.login(formData).then(
        (resp) => {
          if (resp.status === 200) {
          setLoading(false);
          Helpers.createLSByName("token", resp.data.token);
          Helpers.createLSByName("userInfo", resp.data.response);
          Helpers.getComponent(resp.data.response.role_id,resp.data.response.role.role_key,history);
			  
              
          }else {
            console.log("error",resp);
          }
        }
      ) .catch((error) => {
		    setLoading(false);
			if (error.response && error.response.status === 400) {
			  if(!error.response.data.validator)
			  {
				setErrors({'key':'common','message':error.response.data.error_msg});
			  }
			  else{
          setErrors({'key':'common','message':error.response.data.error_msg[0]}); 
			  }
			}
       
      });
    } catch (error) {
		const errorData = JSON.parse(error.message);
		setErrors({'key':errorData.key,'message':errorData.message});
    }
  };
  
  

  return (
    <ThemeProvider theme={defaultTheme}>
      <div>
             <Grid container component="main" style={{background:"url(/loginbg.jpg)"}} sx={{ height: '100vh', }}>

        
        <Grid item xs={12} sm={12} md={12} component={Paper} elevation={6} square>
          <Box
              className="loginbody"
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
          
            }}
          >
           <img src="/logo.png" style={{width: "132px"}} />
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
				error={Boolean(errors.key == 'email'?true:false)}
                helperText={(Boolean(errors.key == 'email'?true:false))?errors?.message:""}
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
				error={Boolean(errors.key == 'password'?true:false)}
                helperText={(Boolean(errors.key == 'password'?true:false))?errors?.message:""}
                autoComplete="current-password"
              />
           
			  {errors.key === 'common' ? <div className="errrormeg">{errors?.message}</div> : ''}
              <Button
              className="btnbig"
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>
              <Typography variant="body2" color="text.secondary" align="center">
              <Link to="/searchCompany">Select another Company</Link>
            </Typography>
			  
			  <div className="loader2">
				  {loading ? (
					<CircularProgress style={{color:'#ED9F2A'}}/>
				  ) : ''}
				</div>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
      </div>
 
    </ThemeProvider>
  );
}