import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useState } from 'react';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { AuthApi,Helpers } from '../service/index'; 
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import '../css/style.css';



function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="#">
        Smart Attendance
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function SearchCompany() {
	
	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState({'key':false,'message':''});
	const navigate = useNavigate();

    const handleSubmit = (event) => {
	setErrors({'key':'','message':''});	
    event.preventDefault();
    try{ 
    const data = new FormData(event.currentTarget);
	
      const formData = new FormData();
	  if(!data.get('company'))
	  {
		throw new Error(JSON.stringify({'key':'company','message':'company is required'}));
	  }
	  
	  
	  
      formData.append("company", data.get('company'));
      setLoading(true);
      AuthApi.searchCompany(formData).then(
        (resp) => {
          if (resp.status === 200) {
			        setLoading(false);
              Helpers.createLSByName("institution_id", resp.data.data.institution_config_detail_id);
              navigate('/login', { replace: true})
          }else {
            console.log("error",resp);
          }
        }
      ) .catch((error) => {
		    setLoading(false);
			if (error.response && error.response.status === 400) {
			  if(!error.response.data.validator)
			  {
				  setErrors({'key':'common','message':error.response.data.error_msg});
			  }
			  else{
				console.log(error.response.data.error_msg); 
			  }
			}
       
      });
    } catch (error) {
		const errorData = JSON.parse(error.message);
		setErrors({'key':errorData.key,'message':errorData.message});
    }
  };
  
  

  return (
    <ThemeProvider theme={defaultTheme}>
      <div>
             <Grid container component="main" style={{background:"url(/loginbg.jpg)"}} sx={{ height: '100vh', }}>

        
        <Grid item xs={12} sm={12} md={12} component={Paper} elevation={6} square>
          <Box
              className="loginbody"
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
          
            }}
          >
           <img src="/logo.png" style={{width: "132px"}} />
            <Typography component="h1" variant="h5">
              Search Company
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="company"
                label="Company Name"
                name="company"
                autoComplete="company"
				        error={Boolean(errors.key == 'company'?true:false)}
                helperText={(Boolean(errors.key == 'company'?true:false))?errors?.message:''}
                autoFocus
              />
              
           
			  {errors.key === 'common' ? <div className="errrormeg">{errors?.message}</div> : ''}
              <Button
              className="btnbig"
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>
			  
			  <div className="loader2">
				  {loading ? (
					<CircularProgress />
				  ) : ''}
				</div>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
      </div>
 
    </ThemeProvider>
  );
}