import React, { useState } from 'react';
import { GoogleMap, LoadScript, Autocomplete } from '@react-google-maps/api';

const MapContainer = () => {
  const [autocomplete, setAutocomplete] = useState(null);
  const [address, setAddress] = useState('');

  const handlePlaceSelect = () => {
    const addressObject = autocomplete.getPlace();
    setAddress(addressObject.formatted_address);
  };

  return (
    <LoadScript
      // googleMapsApiKey="AIzaSyB1aMf4AS3UjH2YN4s-3IyzzT6Gdh46PRw"
      libraries={['places']} // Add this line to include the 'places' library
    >
      <GoogleMap
        mapContainerStyle={{ width: '100%', height: '400px' }}
        center={{ lat: -34.397, lng: 150.644 }}
        zoom={8}
      >
        <Autocomplete
          onLoad={(autocomplete) => setAutocomplete(autocomplete)}
          onPlaceChanged={handlePlaceSelect}
        >
          <input
            type="text"
            placeholder="Enter your address"
            style={{
              boxSizing: 'border-box',
              border: '1px solid transparent',
              width: '400px',
              height: '32px',
              padding: '0 12px',
              borderRadius: '3px',
              boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
              fontSize: '14px',
              outline: 'none',
              textOverflow: 'ellipses',
              position: 'absolute',
              left: '18%',
              marginTop: '10px',
            }}
          />
        </Autocomplete>
      </GoogleMap>
    </LoadScript>
  );
};

export default MapContainer;
