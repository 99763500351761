import * as React from 'react';
import { useEffect, useState } from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { ConfigApi, Helpers } from '../service/index';
import { Link } from 'react-router-dom';
import DeleteConfirmComponent from '../components/DeleteConfirmComponent';
import CircularProgress from '@mui/material/CircularProgress';
import '../css/style.css';
const drawerWidth = 240;

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();
const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);
export default function Shift() {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(true);
  const [errors, setErrors] = useState({ 'key': false, 'message': '' });
  const [shiftData, setShiftData] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeleteId, setDeleteId] = useState(0);
  const [locationId, setLocationId] = useState('');
  const [locationData, setLocationData] = useState([]);

  const handleDeleteClick = (id) => {
    setDeleteId(id);
    setIsDeleteModalOpen(true);
  };

  const handleConfirmDelete = () => {
    deleteShift()
      .then(() => {
        setShiftData((prevShiftData) => prevShiftData.filter((shift) => shift.id !== isDeleteId));
        setIsDeleteModalOpen(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  
  
  

  const handleCancelDelete = () => {
    setIsDeleteModalOpen(false);
  };

  const toggleDrawer = () => {
    setOpen(!open);
  };
  useEffect(() => {
    const userInfoString = localStorage.getItem('userInfo');
    const userInfo = JSON.parse(userInfoString);
    setLocationId(userInfo.office_location);
    getLocation();
    getShiftByLocation(userInfo.office_location);
  }, []);
  const convertTo12Hour = (time) => {
    const [hours, minutes] = time.split(':');
    const parsedHours = parseInt(hours, 10);

    const period = parsedHours >= 12 ? 'PM' : 'AM';
    const displayHours = parsedHours % 12 === 0 ? 12 : parsedHours % 12;

    return `${displayHours}:${minutes} ${period}`;
  };

  const getLocation = async () => {

    await ConfigApi.location().then(
      (resp) => {
        if (resp.status === 200) {
          setLocationData(resp.data.response);
        } else {
          console.log("error", resp.data.error_msg);
          alert(resp.data.error_msg);
        }
      }
    ).catch((error) => {
      Helpers.error401(error);
    });
  };

  const getShift = () => {
    const formData = new FormData();
    formData.append("location_name", 'SmartCRM');
    setLoading(true);
    ConfigApi.shift(formData).then(
      (resp) => {
        if (resp.status === 200) {
          setLoading(false);
          setShiftData(resp.data.response);
          setIsDeleteModalOpen(false);
        } else {
          console.log("error", resp.data.error_msg);
        }
      }
    ).catch((error) => {
      setLoading(false);
      Helpers.error401(error);
    });
  };

  const getShiftByLocation = (id) => {
    const formData = new FormData();
    formData.append("location_id", id);
    setLoading(true);
    ConfigApi.shiftByLocation(formData).then(
      (resp) => {
        if (resp.status === 200) {
          setLoading(false);
          setShiftData(resp.data.response);
          setIsDeleteModalOpen(false);
        } else {
          console.log("error", resp.data.error_msg);
        }
      }
    ).catch((error) => {
      setLoading(false);
      Helpers.error401(error);
    });
  };

  const deleteShift = () => {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("isDeleteId", isDeleteId);
      ConfigApi.deleteShift(isDeleteId)
        .then((resp) => {
          if (resp.status === 200) {
            resolve();
          } else {
            console.log("error", resp.data.error_msg);
            reject("Deletion failed");
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  


  try {


  } catch (error) {
    // debugger;
    console.log(error);
    const errorData = JSON.parse(error.message);
    setErrors({ 'key': errorData.key, 'message': errorData.message });
  }

  const selectLocation = async (event) => {
    setLocationId(event.target.value);
    getShiftByLocation(event.target.value);
  };

  return (
    <div style={{ "padding": "0 30px", }}>

      <Typography className={Helpers.cmAccess('SHIFT_ADD')} variant="h5" component="div" style={{ "fontFamily": "'Montserrat', sans-serif", "fontSize": "24px", "fontWeight": "600", "marginBottom": "27px" }}>
        Shifts <Link to="/dashboard/shift/addshift" style={{ float: "right", "fontSize": "14px", "textDecoration": "none", "color": "#000", "padding": "7px 16px", "border": "1px solid #d7d7d7", "marginTop": "8px", background: "#fff" }}>Add Shift </Link>

      </Typography>
      <Grid className={Helpers.cmAccess('SHIFT_LOCATION_LIST')} item xs={12} sm={12} md={6} style={{ "text-align": "initial", }}>
        <p className="textyu">Location</p>
        <select className="formControl form23" style={{width:"21% !important"}} value={locationId} onChange={selectLocation} >
          {locationData.length > 0 && locationData?.map((item, index) => (
            <option key={item.office_location_id} value={item.office_location_id}>{item.name}</option>
          ))}
        </select>
      </Grid>
      <Grid container spacing={2} mt={2}>
        {shiftData.map((content, index) => (
          <Grid item sm={6} xs={12} md={4} lg={4}>
            <Card className="locationborder">

              <CardContent>
                <img src="/landscape-mode.png" className="shifticons" />

                <div className="shiftdiv">
                  <div className="coulam">
                    <Typography variant="h6" component="div" className="shiftcardlabel">
                      Shift Name
                    </Typography>
                    <Typography variant="h6" component="div" className="shiftcard">
                      {content.shift_name}
                    </Typography>
                  </div>

                  <div className="coulam">

                    <Typography variant="h6" component="div" className="shiftcardlabel">
                      Timing
                    </Typography>
                    <Typography variant="h6" component="div" className="shiftcard" >
                      {convertTo12Hour(content.start_time)} to {convertTo12Hour(content.end_time)}
                    </Typography>
                  </div>
                </div>
                {/**<Typography variant="h6" component="div" style={{"fontFamily":"'Montserrat', sans-serif","fontSize":"14px"}}>
        <i className="bi bi-geo-alt"></i> Vaishali nagar Jaipur 
        </Typography> **/}
                <div className="btnloc">
                  <div className={Helpers.cmAccess('ROLE_EDIT')}>
                    <Link to={`/dashboard/shift/addshift/${content.id}`} style={{ "padding": "7px 0", "fontSize": "14px", "fontFamily": "Montserrat", "marginRight": "11px", "color": "#3ec312", "textDecoration": "none", "fontWeight": "600", cursor: "pointer" }}><i class="bi bi-pencil-square"></i> Edit Shift </Link>
                  </div>
                  <div className={Helpers.cmAccess('ROLE_DELETE')}>
                    <button onClick={() => handleDeleteClick(content.id)} style={{ "padding": "7px 0", "background": "none", "border": "none", "fontSize": "14px", "fontFamily": "'Montserrat'", "color": "#d30c0c", "fontWeight": "600", cursor: "pointer" }}><i class="bi bi-trash3"></i> Delete</button>
                  </div>

                </div>



              </CardContent>

            </Card>
          </Grid>
        ))}

        <DeleteConfirmComponent
          isOpen={isDeleteModalOpen}
          onConfirm={handleConfirmDelete}
          onCancel={handleCancelDelete}
        />
        <div className='loader'>
          {loading ? (
            <CircularProgress style={{color:'#ED9F2A'}} />
          ) : ''}
        </div>
      </Grid>
    </div>
  );
}