import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';


export default function AddressForm() {
  return (
    <React.Fragment>
    
      <Grid container spacing={3} style={{"width":"50%","margin":"auto","border":"1px solid #ddd","borderRadius":"16px","padding":"24px","paddingLeft":"0"}}>
      <Grid item xs={4} sm={4}>
      <Typography variant="p" gutterBottom>
         <Box component="div" sx={{ display: 'inline' }} style={{"background":"#f60","padding":"6px 11px","borderRadius":"60px","marginRight":"6px","fontSize":"18px","color":"#fff"}}>1</Box> 
          Personal Info
      </Typography>
        </Grid>
        <Grid item xs={4} sm={4}>
      <Typography variant="p" gutterBottom>
         <Box component="div" sx={{ display: 'inline' }} style={{"background":"#999","padding":"6px 11px","borderRadius":"60px","marginRight":"6px","fontSize":"18px","color":"#fff"}}>2</Box> 
        Address 
      </Typography>
        </Grid>
        <Grid item xs={4} sm={4}>
      <Typography variant="p" gutterBottom>
         <Box component="div" sx={{ display: 'inline' }} style={{"background":"#999","padding":"6px 11px","borderRadius":"60px","marginRight":"6px","fontSize":"18px","color":"#fff"}}>3</Box> 
      Location
      </Typography>
        </Grid>
      <Grid item xs={12} sm={12}>
      <Typography variant="h6" gutterBottom>
        Personal Information
      </Typography>
        </Grid>
        
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="address1"
            name="address1"
            label="First Name"
            fullWidth
            autoComplete="shipping address-line1"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="address2"
            name="address2"
            label="Last Name"
            fullWidth
            autoComplete="shipping address-line2"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="city"
            name="Email"
            label="Email"
            fullWidth
            autoComplete="shipping address-level2"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="password"
            name="password"
            label="Password"
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="Status"
            name="Status"
            label="Status"
            fullWidth
            autoComplete="shipping postal-code"
            variant="standard"
          />
        </Grid>
     
       
        <Grid item xs={12}>
           <Button variant="contained" style={{"background":"#f60","padding":"9px 40px","fontWeight":"600","float":"right"}}>Next</Button>
        </Grid>


      </Grid>
    </React.Fragment>
  );
}