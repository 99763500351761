import React, { useState, useEffect } from "react";
import { ConfigApi, Helpers, UserApi } from '../service/index';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import '../css/style.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { NavLink } from 'react-router-dom';
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';

// TODO remove, this demo shouldn't need to reset the theme.
export default function MainPageResponses() {
  const [open, setOpen] = React.useState(true);
  const [getPagesDetails, setPagesDetails] = useState([]);
  const [loading, setLoading] = useState(false);

  
  let { id } = useParams();

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const callAPI = (url,type) => {
    setLoading(true);
    if (url) {
      ConfigApi.paginationApiCall(url).then(
        (resp) => {
          if (resp.status === 200) {
			  if('getPageDetails' === type)
			  {
				setPagesDetails(resp.data.data);
			  }
              setLoading(false);
          } else {
            setLoading(false);
            console.log("error", resp.data.error_msg);
          }
        }
      ).catch((error) => {
        setLoading(false);
        Helpers.error401(error);
      });
    }
  };
  useEffect(() => {
    
    callAPI("/getPageDetails?pageConfigDetailId="+id,'getPageDetails');
  }, []);
  
  
  

  return (
    <div style={{ background: '#fff', "padding": "0 30px", "borderRadius": "10px" }}>
		
        <>
        <p className="headingresponce"> {getPagesDetails?.pageQuestion} </p>
        </>
        <TableContainer className={Helpers.cmAccess('REPORT_TABLE')} component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>

                <TableCell>Date</TableCell>
               
                <TableCell align="right">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
	   {getPagesDetails?.pageConfigDetails?.map((pageConfigDetail, index) => (
	    
		<TableRow
                  key={pageConfigDetail.pageDetailId}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
		 <TableCell component="th" scope="row" key={`row1${pageConfigDetail.pageDetailId + index + 1}`}>{pageConfigDetail.first_name} {pageConfigDetail.last_name}</TableCell>
         <TableCell component="th" scope="row" key={`row2${pageConfigDetail.pageDetailId + index + 1}`}> {pageConfigDetail.startDate?format(new Date(pageConfigDetail.startDate), 'd-M-y'):''}</TableCell>
         <TableCell className="rightext" component="th" scope="row" key={`row3${pageConfigDetail.pageDetailId + index + 1}`}>
		  <NavLink  key={`pageConfig${index}`} to={`/dashboard/page/${pageConfigDetail.pageConfigDetailId}/${pageConfigDetail.pageHeaderId}`} >Edit</NavLink>
          </TableCell>
          </TableRow>
		
		
		 ))}
		   </TableBody>
           </Table>
         </TableContainer>
         <div className='loader'>
          {loading ? (
            <CircularProgress style={{color:'#ED9F2A'}}/>
          ) : ''}
        </div>
    </div>
  );
}




