import smartAttendence from "../axios/axios";

class AuthService {
    
    login(data) {
        return Promise.resolve(smartAttendence.post('login', data));
    }
    
    searchCompany(data) {
        return Promise.resolve(smartAttendence.post('search-company', data));
    }
    
}

export default AuthService;