import React from 'react';
import Modal from 'react-modal';
import '../css/style.css';


function DeleteConfirmComponent({ isOpen, onConfirm, onCancel }) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCancel}
      contentLabel="Delete Confirmation"
      style={{margin:"auto"}}
    >
     
       <img src="/trash.png" className="trashimg"/>
  
      <h2>Confirm Delete</h2>
      <p>Are you sure you want to delete?</p>
      <div className="iconsdel">
      <button className="yesbtn" onClick={onConfirm}>Yes</button>
      <button className="nobtn" onClick={onCancel}>No</button>
      </div>
    </Modal>
  );
}

export default DeleteConfirmComponent;
