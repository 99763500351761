import * as React from 'react';
import { useParams } from 'react-router-dom';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { useEffect, useState } from 'react';
import { ConfigApi,Helpers } from '../service/index'; 
const drawerWidth = 240;

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();
const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);
export default function Addshift() {
  const [open, setOpen] = React.useState(true);
  const [shift_name, setshift_name] = useState('');
  const [start_time, setstart_time] = useState('');
  const [end_time, setend_time] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [errors, setErrors] = useState({});
  const [ids, setIds] = useState(0);
  const [locationId, setLocationId] = useState('');
  const [locationData, setLocationData] = useState([]);
 
  let { id } = useParams();
  
  const toggleDrawer = () => {
    setOpen(!open);
  };
    useEffect(() => {
	getLocation();
	if(id)
	  {
		setIds(id);
		editShift(id);
	  }
	}, []);
  function isJsonString(str) {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
}

const getLocation = async () => {

    await ConfigApi.location().then(
        (resp) => {
            if (resp.status === 200) {
                setLocationData(resp.data.response);
				setLocationId(resp.data.response[0].office_location_id);
            } else {
                console.log("error", resp.data.error_msg);
                alert(resp.data.error_msg);
            }
        }
    ).catch((error) => {
        /* alert(error);*/
        Helpers.error401(error);
    });
};


const selectLocation = async (event) => {
    setLocationId(event.target.value);
};

const handleSubmit = (event) => {
	try{ 
	    const loggedUser = Helpers.loggedUserInfo();
		event.preventDefault();
		setErrors({});
		const data = new FormData(event.currentTarget);
		var error = {};
		if(!data.get('shift_name'))
		  {
			error = {"shift_name":"shift name required","isError":true};
		  }
		  
		  if(!data.get('start_time'))
		  {
			error['start_time'] = "Start time required";
			error['isError'] = true;
		  }
		  
		  if(!data.get('end_time'))
		  {
			error['end_time'] =  "End time required";
			error['isError'] = true;
		  }
		  if(error.isError)
		  {
			throw new Error(JSON.stringify(error));
		  }
		  
		 var dataInfo = {
			"location_id":locationId, 
			"shift_name":shift_name, 
			"start_time":start_time, 
			"end_time":end_time
		 };
		 ids?updateShift(JSON.stringify(dataInfo)):addShift(JSON.stringify(dataInfo));
		 
	 } catch (error) {
	
		 let errorData = {"isError":true,"common":error.message};
		if(isJsonString(error.message))
		{
			errorData = JSON.parse(error.message);
		}
		
		setErrors(errorData);
    }
  };
  
	const addShift = (formData) => {
	  
	  ConfigApi.addShift(formData).then(
		(resp) => {
		  if (resp.status === 200) {
			Helpers.toastSuccess('Shift created successfully');
			window.location.href="/dashboard/shift";
		  }else {
			console.log("error",resp.data.error_msg);
		  }
		}
	  ) .catch((error) => {
		Helpers.error401(error);
	  });
	};
	
	const updateShift = (formData) => {
	  
	  ConfigApi.updateShift(formData,ids).then(
		(resp) => {
		  if (resp.status === 200) {
			Helpers.toastSuccess('Shift updated successfully');
			 window.location.href="/dashboard/shift";
		  }else {
			console.log("error",resp.data.error_msg);
		  }
		}
	  ) .catch((error) => {
		Helpers.error401(error);
	  });
	};
	
	const editShift = (id) => {
	  
	  ConfigApi.editShift(id).then(
		(resp) => {
		  if (resp.status === 200) {
			const editInfo = resp.data.response;
			setshift_name(editInfo.shift_name);
			setstart_time(editInfo.start_time);
			setend_time(editInfo.end_time);
			setLocationId(editInfo.location_id);
		  }else {
			console.log("error",resp.data.error_msg);
		  }
		}
	  ) .catch((error) => {
		Helpers.error401(error);
	  });
	};
  
  return (
    <div style={{"padding":"0 30px",}}>
      
      <Typography variant="h5" component="div" style={{"fontFamily":"'Montserrat', sans-serif","fontSize":"24px","fontWeight":"600","marginBottom":"27px"}}>
       Add Shifts
        </Typography>
		<form onSubmit={handleSubmit} className="shiftForm">
        <Grid container spacing={2}>

		<Grid item xs={12}  lg={3}>
                                    <p className="labelname">Location</p>
                                    <select className="formControl" value={locationId}  onChange={(e) => selectLocation(e)} >
                                        {locationData.length > 0 && locationData?.map((item, index) => (
                                            <option value={item.office_location_id} >{item.name}</option>))
                                        }
                                    </select>
                                </Grid>	

       <Grid item xs={12}  lg={3}>
          <p className="labelname">Shift Name:</p>
          <input type="text" name="shift_name" value={shift_name} onChange={(e) => setshift_name(e.target.value)} className="form-control" />
		   <div className="rederror">{errors?.shift_name}</div>
        </Grid>
       <Grid item xs={12}  lg={3}>
          <p className="labelname">Start Time:</p>
          <input type="time" name="start_time" value={start_time} onChange={(e) => setstart_time(e.target.value)}  className="form-control" />
		  <div className="rederror">{errors?.start_time}</div>
         </Grid>
        <Grid item xs={12}  lg={3}>
          <p className="labelname">End Time:</p>
          <input type="time" name="end_time" value={end_time} onChange={(e) => setend_time(e.target.value)} className="form-control"  />
		   <div className="rederror">{errors?.end_time}</div>
        </Grid>
		 <Grid className={Helpers.cmAccess('SHIFT_EDIT_SUBMIT')} item xs={12}  lg={3}>
        <button type="submit" variant="contained" className="btnorange mt-3">Submit</button>
			
		       </Grid>
			   <div>{errors?.common}</div>

	  
		  

</Grid>
</form>
      </div>
  );
}