import axios from "axios";

 
let token = "";
if(localStorage.getItem('token'))
{
	token = JSON.parse(localStorage.getItem('token'));
}
const smartAttendence = axios.create({

    baseURL: "https://smartcrm.smartmanager.in/api/",
    timeout: 60000,
    headers: {
        "Content-type": "application/json",
    }
});

// request interceptor for adding token
smartAttendence.interceptors.request.use((config) => {
    // add token to request headers
    config.headers.Authorization = `Bearer ${token}`;
    return config;
});

export default smartAttendence;