import { Box, Typography, TextField, Button } from '@mui/material'
import React from 'react'
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { orange } from '@mui/material/colors';


const theme = createTheme({
  palette: {
    primary: {
      main: orange[800], //#ff9800
    },
    secondary: {
      main: '#9e9e9e',
    },
  },
});


const Forgotpass = () => {
  return (
    <ThemeProvider theme={theme}>
      <div>
        <form >
          <Box display="flex" flexDirection={'column'} maxWidth={320} height={350}
            justifyContent={"center"} margin="auto" marginTop={7} padding={3} borderRadius={2} boxShadow={"1px 1px 10px #ccc"}
          >
            <Box display="flex" justifyContent={"space-between"} marginBottom={2}  >
              <Typography variant='h5'> Forgot password  </Typography>
            </Box>
            <Typography marginBottom={3} color={'grey'}>To Rest your password <br />Please Enter Your Email Address or Phone <br /> Number </Typography>
            <Typography >Email</Typography>
            <TextField type='Email' variant='outlined' placeholder='Email' /><br />
            <Button margintop={10} variant="contained" disableElevation >
              Save changes
            </Button>
            <Button color="secondary" variant="text">Close</Button>
          </Box>
        </form>
      </div>
    </ThemeProvider>
  );
}

export default Forgotpass;

