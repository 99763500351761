import React from 'react';
import { styled } from '@mui/system'; // Updated import statement
import Table from '@mui/material/Table';
import Typography from '@mui/material/Typography';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';

// Define the styles using the styled function
const StyledTableCell = styled(TableCell)({
  fontWeight: 'bold',
  // Add other styling properties as needed, e.g., paddingRight, paddingLeft
});

const UserListTable = () => {
  // No need for the 'classes' object in this case

  // Sample data, replace this with your actual user data
  const userList = [
    { id: 1, username: 'Arvind Sharma', location: 'Jaipur', status: 'Active' },
    { id: 2, username: 'Manoj Singh', location: 'Jaipur', status: 'Inactive' },
    { id: 2, username: 'Deepak sharma', location: 'Jaipur', status: 'Inactive' },
    { id: 2, username: 'Deepesh Jangid', location: 'Jaipur', status: 'Inactive' },
    { id: 2, username: 'Saurabh Singh', location: 'Jaipur', status: 'Inactive' },
    { id: 2, username: 'Sanjeet Singh', location: 'Jaipur', status: 'Inactive' },
    { id: 2, username: 'Ravi Raj Sharma', location: 'Jaipur', status: 'Inactive' },
    // Add more user data as needed
  ];

  return (
    <div className="tableUser" style={{marginLeft:20,}}>
      <div>
     

      <Grid container spacing={2}>
  <Grid item xs={6}>
  <Typography variant="h5" mx={2} component="h2" style={{ "fontFamily": "'Montserrat', sans-serif", "fontSize": "20px", "fontWeight": "600", marginBottom: "19px", }}>
  Employee List
</Typography>
  </Grid>
  <Grid item xs={6}>
  <div style={{marginLeft:20,textAlign:"right"}}><Button variant="outlined" style={{borderColor:"#f60"}}><Link className="colrolink" style={{textDecoration:"none",color:"f60 !important", fontFamily:"Montserrat, sans-serif", fontSize:"14px", }} href="/dashboard/register"><i class="bi bi-plus-lg"></i> Add Employee</Link></Button></div>
  
  </Grid>
</Grid>
</div>
      <TableContainer component={Paper} sx={{ overflowX: 'auto'}}>
        <Table sx={{ minWidth: 650 }} aria-label="User List Table">
          <TableHead>
            <TableRow>
              <StyledTableCell>User Name</StyledTableCell>
              <StyledTableCell>Location</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userList.map((user) => (
              <TableRow key={user.id}>
                <TableCell>{user.username}</TableCell>
                <TableCell>{user.location}</TableCell>
                <TableCell>{user.status}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default UserListTable;
