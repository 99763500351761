import * as React from 'react';
import { useState, useEffect } from "react";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { ConfigApi, Helpers } from '../service/index';
import { debounce } from '@mui/material';
import MapContainer from './MapContainer';
import { Margin } from '@mui/icons-material';

const drawerWidth = 240;
// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();
const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);
export default function Addlocation() {
  const [open, setOpen] = React.useState(true);
  const [locationName, setlocationName] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [errors, setErrors] = useState({});
  const [ids, setIds] = useState(0);
  let { id } = useParams();
  const [status, setStatus] = useState("active");
  const [countryName, setcountryName] = useState([]);
  const [stateName, setstateName] = useState('');
  const [cityName, setcityName] = useState('');
  const [countryid, setcountryid] = useState('');
  const [stateid, setstateid] = useState('');
  const [cityid, setcityid] = useState('');

  const toggleDrawer = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (id) {
      addcountry(false);
      setIds(id);
      editLoation(id);
    }
    else{
      addcountry(true);
    }
  }, []);

  function isJsonString(str) {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  }
  const handlecountry = (event) => {
    const getcountryid = event.target.value;
    setcountryid(getcountryid);
    addstate(getcountryid, true);

  }
  const handlestate = (event) => {
    const getstateid = event.target.value;
    setstateid(getstateid);
    addcity(getstateid);

  }
  const handlecity = (event) => {
    const getcityid = event.target.value;
    setcityid(getcityid);

  }
  const handleSubmit = (event) => {
    try {
      const loggedUser = Helpers.loggedUserInfo();
      event.preventDefault();
      setErrors({});
      const data = new FormData(event.currentTarget);
      var error = {};
      if (!data.get('locationName')) {
        error = { "locationName": " Location name required", "isError": true };
      }
      if (error.isError) {
        throw new Error(JSON.stringify(error));
      }

      var dataInfo = {
        "locationName": locationName,
        "country": countryid,
        "state": stateid,
        "city": cityid,
        "status": status
      };
      ids ? updateLocation(JSON.stringify(dataInfo)) : addLoation(JSON.stringify(dataInfo));

    } catch (error) {
      let errorData = { "isError": true, "common": error.message };
      if (isJsonString(error.message)) {
        errorData = JSON.parse(error.message);
      }
      setErrors(errorData);
    }
  };

  const addLoation = (formData) => {
    ConfigApi.addLoation(formData).then(
      (resp) => {
        if (resp.status === 200) {
          Helpers.toastSuccess('Location created successfully');
          window.location.href = "/dashboard/location";
        } else {
          console.log("error", resp.data.error_msg);
        }
      }
    ).catch((error) => {
       Helpers.error401(error);
    });
  };

  const updateLocation = (formData) => {
     ConfigApi.updateLocation(formData, ids).then(
      (resp) => {
        if (resp.status === 200) {
          Helpers.toastSuccess('Location updated successfully');
          window.location.href = "/dashboard/location";
        } else {
          console.log("error", resp.data.error_msg);
        }
      }
    ).catch((error) => {
      /* if (error.response) {
       let errorData = { "isError": true, "common": error.response.data.validator?error.response.data.error_msg[0]: error.response.data.error_msg};
       setErrors(errorData);
     }*/
     Helpers.error401(error);
    });
  };

  const editLoation = (id) => {
    ConfigApi.editLocation(id).then(
      (resp) => {
        if (resp.status === 200) {
          const editInfo = resp.data.response;
          setlocationName(editInfo.name);
          setcountryid(editInfo.country);
          addstate(editInfo.country, false);
          addcity(editInfo.state);
          setStatus(editInfo.status);
          setstateid(editInfo.state);
          setcityid(editInfo.city);

        } else {
          console.log("error", resp.data.error_msg);
        }
      }
    ).catch((error) => {
      Helpers.error401(error);
    });
  };

  const addcountry = (cid) => {
    ConfigApi.getcountry().then(
      (resp) => {
        if (resp.status === 200) {
          setcountryName(resp.data.data);
          if(cid){
          addstate(resp.data.data[0].id, true);
          }
        } else {
          console.log("error", resp.data.error_msg);
        }
      }
    ).catch((error) => {
      Helpers.error401(error);
    });
  };

  const addstate = (id, city) => {
    ConfigApi.getstate(id).then(
      (resp) => {
        if (resp.status === 200) {
          setstateName(resp.data.data);
          if(city){
          addcity(resp.data.data[0].id);
          }
        } else {
          console.log("error", resp.data.error_msg);
        }
      }
    ).catch((error) => {
      Helpers.error401(error);
    });
  };

  const addcity = (id) => {
    ConfigApi.getcity(id).then(
      (resp) => {
        if (resp.status === 200) {
          setcityName(resp.data.data);
        } else {
          console.log("error", resp.data.error_msg);
        }
      }
    ).catch((error) => {
      Helpers.error401(error);
    });
  };



  return (
    <div style={{ "padding": "0 30px", }}>
      <div className="">
        <form onSubmit={handleSubmit} className="roleForm">
          <Typography variant="h5" component="div" style={{ "fontFamily": "'Montserrat', sans-serif", "fontSize": "24px", "fontWeight": "600", "marginBottom": "27px" }}>
            {ids > 0 ? "Edit" : "Add"} Location
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <div>
                <label htmlFor="Location" className="labelname">Address:</label>
                <MapContainer />
              </div>
            </Grid>
            <Grid item xs={12} lg={3}>
              <div className="col-md-4">
                <label htmlFor="Location" className="labelname">Location:</label>
                <input type="text" name="locationName" value={locationName} onChange={(e) => setlocationName(e.target.value)} className="selectdrop" placeholder="Location" />
                <div className="rederror">{errors?.locationName}</div>
                <Grid item xs={12} lg={12} style={{"margin-top": "20px"}}>
                  <label htmlFor="Latitude" className="labelname">Latitude:</label>
                  <input type="text" name="Latitude" value={latitude} onChange={(e) => setLatitude(e.target.value)} className="selectdrop" placeholder="Latitude" />
                  <div className="rederror">{errors?.latitude}</div>
                </Grid>  
              </div>  
            </Grid>
            <Grid item xs={12} lg={3} container justifyContent="flex-end">
              <div className="col-md-4">
                <label htmlFor="statusDropdown" className="labelname">Status:</label>
                <select id="statusDropdown" name="status" value={status} onChange={(e) => setStatus(e.target.value)} className="selectdrop">
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </select>
                <Grid item xs={12} lg={12} style={{"margin-top": "20px"}}>
                  <label htmlFor="Longitude" className="labelname">Longitude:</label>
                  <input type="text" name="Longitude" value={longitude} onChange={(e) => setLongitude(e.target.value)} className="selectdrop" placeholder="Longitude" />
                  <div className="rederror">{errors?.longitude}</div>
                </Grid>
                <Grid item xs={12} lg={12} style={{"margin-left": "192px"}}>
                  <Button variant="contained" type="submit" className="btnmain">Submit</Button>
                  <div className="rederror">{errors?.common}</div>
                </Grid>
              </div>
            </Grid>
          </Grid>

        </form>

      </div>

    </div>
  );
}