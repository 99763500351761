import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Box, Button } from '@mui/material';
import { grey } from '@mui/material/colors';



const theme = createTheme({
  palette: {
    primary: {
      main: grey[900], //#ff9800
    },
    secondary: {
      main: '#e65100',
    },
  },
});
export default function Previousservice() {
  return (
    <ThemeProvider theme={theme}>
      <React.Fragment>
        <div>
          <form >
            <Box display="flex" flexDirection={'column'} maxWidth={500} height={500}
              justifyContent={"center"} margin="auto" marginTop={7} padding={3} borderRadius={2} boxShadow={"1px 1px 10px #ccc"}>
              <Typography variant="h6" gutterBottom>
                Previous Service Record
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="firstName"
                    name="firstName"
                    label="First name"
                    fullWidth
                    autoComplete="given-name"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="lastName"
                    name="lastName"
                    label="Last name"
                    fullWidth
                    autoComplete="family-name"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="Date1"
                    name="Date1"
                    label="StartDate"
                    fullWidth
                    autoComplete="StartDate"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="Date2"
                    name="Date2"
                    label="EndDate"
                    fullWidth
                    autoComplete="EndDate"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="Role"
                    name="Role"
                    label="Role"
                    fullWidth
                    autoComplete="Role"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} >
                  <TextField
                    required
                    id="Reason of Leaving"
                    name="Reason of Leaving"
                    label="Reason of Leaving"
                    fullWidth
                    autoComplete="Reason of Leaving"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} >
                  <TextField
                    required
                    id="Employee Image"
                    name="Employee Image"
                    label="Employee Image"
                    fullWidth
                    autoComplete="Employee Image"
                    variant="outlined"
                  />
                </Grid>
                <React.Fragment>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button color="secondary"
                      variant="contained"
                      sx={{ mt: 3, ml: 3 }}
                    >
                      Submit
                    </Button>
                  </Box>
                </React.Fragment>
              </Grid>
            </Box>
          </form>
        </div>
      </React.Fragment>
    </ThemeProvider>
  );
}
