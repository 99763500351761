import * as React from "react";
import { useNavigate } from "react-router-dom";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MainListItems from "../pages/MainListItems";
import { Helpers } from "../service/index";
import "../css/style.css";
import Menu from '@mui/material/Menu';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Stack from '@mui/material/Stack';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { UserApi, ConfigApi } from '../service';
import Check from '@mui/icons-material/Check';
import Avatar from '@mui/material/Avatar';
import { deepOrange, deepPurple } from '@mui/material/colors';
const drawerWidth = 240;
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,

  typography: {
      fontFamily: 'Montserrat, sans-serif',
  },

}));
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const formatDate = (eventTime) => {
  const dateObj = new Date(eventTime);
  const year = dateObj.getFullYear();
  const month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
  const day = ('0' + dateObj.getDate()).slice(-2);
  let hours = dateObj.getHours();
  const minutes = ('0' + dateObj.getMinutes()).slice(-2);
  const seconds = ('0' + dateObj.getSeconds()).slice(-2);
  const ampm = hours >= 12 ? 'PM' : 'AM';

  // Convert hours to 12-hour format with leading zeroes
  hours = hours % 12 || 12;
  hours = ('0' + hours).slice(-2);

  return `Date: ${year}-${month}-${day} Time: ${hours}:${minutes}:${seconds} ${ampm}`;
};

const formatAvtarTime = (eventTime) => {
  const dateObj = new Date(eventTime);
  let hours = dateObj.getHours();
  const minutes = ('0' + dateObj.getMinutes()).slice(-2);
  // const seconds = ('0' + dateObj.getSeconds()).slice(-2);
  const ampm = hours >= 12 ? 'PM' : 'AM';

  // Convert hours to 12-hour format with leading zeroes
  hours = hours % 12 || 12;
  hours = ('0' + hours).slice(-2);

  return `${hours}:${minutes} \n ${ampm}`;
};

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function SideNavBar() {
  const [open, setOpen] = React.useState(true);
  const [userinfo, setUserInfo] = useState({});
  const navigate = useNavigate();
  const [enentData, setEventData] = useState([]);
  const [eventCount, setEventCount] = useState([]);
  const anchorRef = React.useRef(null);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const refresh = () => {
    navigate("/dashboard/app");
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (anchorRef.current && !anchorRef.current.contains(event.target)) {
        // Check if the clicked element is within the dropdown container
        const isDropdownContentClicked = event.target.closest('.notificationdropdown');
        if (!isDropdownContentClicked) {
          setOpendropdown(false);
        }
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [anchorRef]);
  

  useEffect(() => {
    const intervalId = setInterval(() => {
      getEventHistory();
    }, 5000);
    getEventHistory();
    let loggedUser = Helpers.loggedUserInfo();
    if (loggedUser) {
      setUserInfo(loggedUser);
    }
    return () => clearInterval(intervalId);
  }, []);

  const [opendropdown, setOpendropdown] = React.useState(false);
  

  const markRead = async () => {
      await UserApi.markReadHistory().then(
          (resp) => {
              if (resp.status === 200) {
                console.log(resp.data);
              } else {
                  console.log("error", resp.data.error_msg);
                  alert(resp.data.error_msg);
              }
          }
      ).catch((error) => {
          /* alert(error);*/
          Helpers.error401(error);
      });
  };

  const handleToggle = () => {
    setOpendropdown((prevOpen) => !prevOpen);
    if(eventCount>0){
      markRead();
      getEventHistory();
    }
  };

  const handleNotificationPage = (event) => {
    setOpendropdown(false);
    navigate("/dashboard/notificationlist");
  };

  const getEventHistory = async () => {
      await UserApi.getEventHistory().then(
          (resp) => {
              if (resp.status === 200) {
                console.log(resp.data.unread_count);
                setEventData(resp.data.eventHistory);
                setEventCount(resp.data.unread_count);
              } else {
                  console.log("error", resp.data.error_msg);
                  alert(resp.data.error_msg);
              }
          }
      ).catch((error) => {
          /* alert(error);*/
          Helpers.error401(error);
      });
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpendropdown(false);
    } else if (event.key === 'Escape') {
      setOpendropdown(false);
    }
  }


  const prevOpen = React.useRef(opendropdown);
  React.useEffect(() => {
    if (prevOpen.current === true && opendropdown === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = opendropdown;
  }, [opendropdown]);
  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="absolute"
          open={open}
          style={{
            background: "#fff",
            boxShadow: "none",
            borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
            color: "#000",
            fontFamily: "Montserrat, sans-serif",
          }}
        >
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
              pb: "17px", //
            }}
          >
            <IconButton
              className="arrowmain"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              {/* <i className="bi bi-chevron-right "></i> */}
              <img src="/fav.png" width={32} />
            </IconButton>
            <Typography
              sx={{ flexGrow: 1 }}
              component="h1"
              variant="h6"
              noWrap
              style={{
                fontFamily: "'Montserrat', sans-serif",
                fontSize: "20px",
                fontWeight: "600",
                padding: " 0 0px",
              }}
            >
              Hey!, {userinfo?.first_name} {userinfo?.last_name}{" "}
              <img src="/wave.png" className="helloicons" />
            </Typography>

            <Stack direction="row" spacing={2}>
              <div>
                <Button
                  ref={anchorRef}
                  id="composition-button"
                  aria-controls={opendropdown ? 'composition-menu' : undefined}
                  aria-expanded={opendropdown ? 'true' : undefined}
                  aria-haspopup="true"
                  onClick={handleToggle}
                >
                  <Badge badgeContent={eventCount} color="secondary">
                    <NotificationsIcon />
                  </Badge>
                </Button>
                <Popper
                  open={opendropdown}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  placement="bottom-end"
                  transition
                  disablePortal
                  className="notificationdropdown"
                >
                  {({ TransitionProps, placement }) => (
                    <Grow {...TransitionProps}>
                      <Paper sx={{ width: 320 }}>
                        <MenuList
                          autoFocusItem={opendropdown}
                          id="composition-menu"
                          aria-labelledby="composition-button"
                          onKeyDown={handleListKeyDown}
                        >
                          <Grid item xs={6} md={2} sm={3}  style={{ marginBottom: 10, }}>
                              <p className="textyu">    </p>
                              <Button
                                  className="btnoo"
                                  variant="contained"
                                  color="primary"
                                  onClick={handleNotificationPage}
                                  fullWidth
                              >
                                  See All
                              </Button>
                          </Grid>
                          {enentData.length > 0 && enentData.map((item, index) => (
                            <MenuItem key={index}>
                              <ListItemText>
                                <Stack
                                  direction="row"
                                  spacing={2}
                                  style={{ justifyContent: "start", borderBottom: "1px solid #ccc", paddingBottom: "12px" }}
                                >
                                  <Avatar sx={{ bgcolor: deepOrange[500] }}>
                                    <Typography style={{ fontSize: "0.55rem" }}>
                                      {formatAvtarTime(item.event_time)}
                                    </Typography>
                                  </Avatar>
                                  <Typography
                                    variant="inherit"
                                    style={{ fontSize: "15px", color: "#f60", whiteSpace: "break-spaces", color: "#000" }}
                                  >
                                    {item.event_message}
                                  </Typography>
                                </Stack>
                                <Stack
                                  direction="row"
                                  spacing={2}
                                  style={{ justifyContent: "end", borderBottom: "1px solid #ccc" }}
                                >
                                  <Typography
                                    variant="inherit"
                                    style={{ fontSize: "15px", color: "#f60", whiteSpace: "break-spaces", color: "#000" }}
                                  >
                                    {formatDate(item.event_time)}
                                  </Typography>
                                </Stack>
                              </ListItemText>
                            </MenuItem>
                          ))}
                        </MenuList>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </div>
            </Stack>

            <Button
              variant="contained"
              className="logout"
              onClick={() => Helpers.logout("")}
            >
              <Tooltip title="Logout" arrow>
                <i className="bi bi-box-arrow-right"></i>
              </Tooltip>
            </Button>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <Typography
              variant="h1"
              component="h2"
              style={{ fontSize: "41px", fontWeight: "800", color: "#f60" }}
            >
              <img onClick={refresh} src="/logo.png" className="lgoo" />
            </Typography>
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            <MainListItems />
            {/* <Divider sx={{ my: 1 }} />
            {secondaryListItems} */}
          </List>
        </Drawer>

        <Grid container spacing={3}>
          {/* Recent Orders */}
        </Grid>
      </Box>
    </ThemeProvider>
  );
}
