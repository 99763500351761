import smartAttendence from "../axios/axios";

let institution_id = "";
if(localStorage.getItem('token'))
{
	institution_id = JSON.parse(localStorage.getItem('institution_id'));
}


class ConfigService {
   
	
    shift(data) {
        return Promise.resolve(smartAttendence.post(`getShift?institution_id=${institution_id}&channel=browser`, data));
    }
    shiftByLocation(data) {
        return Promise.resolve(smartAttendence.post(`getShift?institution_id=${institution_id}&channel=browser`, data));
    }
	addShift(data) {
        return Promise.resolve(smartAttendence.post(`createShift?institution_id=${institution_id}&channel=browser`, data));
    }
	updateShift(data,id) {
        return Promise.resolve(smartAttendence.post(`createShift/${id}?institution_id=${institution_id}&channel=browser`, data));
    }
	editShift(id) {
        return Promise.resolve(smartAttendence.get(`getShift/${id}?institution_id=${institution_id}&channel=browser`, {}));
    }

	deleteShift(id) {
        return Promise.resolve(smartAttendence.delete(`deleteShift/${id}?institution_id=${institution_id}&channel=browser`, {}));
    }
    Delete(id) {
        return Promise.resolve(smartAttendence.delete(`delete/${id}?institution_id=${institution_id}&channel=browser`, {}));
    }
    location() {
        return Promise.resolve(smartAttendence.get(`getGroupList?institution_id=${institution_id}&channel=browser`));
    }
	addLoation(data) {
        return Promise.resolve(smartAttendence.post(`createGroup?institution_id=${institution_id}&channel=browser`, data));
    }
	editLocation(id) {
        return Promise.resolve(smartAttendence.get(`getGroup/${id}?institution_id=${institution_id}&channel=browser`, {}));
    }
	updateLocation(data,id) {
        return Promise.resolve(smartAttendence.post(`createGroup/${id}?institution_id=${institution_id}&channel=browser`, data));
    }
	deleteLocation(id) {
        return Promise.resolve(smartAttendence.delete(`deleteGroup/${id}?institution_id=${institution_id}&channel=browser`, {}));
    }

    role(data) {
        return Promise.resolve(smartAttendence.get(`getRoleList?institution_id=${institution_id}&channel=browser`,data));
    }
    addRole(data) {
        return Promise.resolve(smartAttendence.post(`createUpdateRole?institution_id=${institution_id}&channel=browser`,data));
    }
    updateRole(data,id) {
        return Promise.resolve(smartAttendence.post(`createUpdateRole/${id}?institution_id=${institution_id}&channel=browser`,data));
    }
    deleteRole(id) {
        return Promise.resolve(smartAttendence.delete(`deleteRole/${id}?institution_id=${institution_id}&channel=browser`,{}));
    }
    editRole(id) {
        return Promise.resolve(smartAttendence.get(`getRole/${id}?institution_id=${institution_id}&channel=browser`,{}));
    }
    getGovtIdList(id) {
        return Promise.resolve(smartAttendence.get(`getGovtIdList?institution_id=${institution_id}&channel=browser`,{}));
    }
   getComponent(id) {
	   
	  

        return Promise.resolve(smartAttendence.get(`getRoleWiseComponents/${id}?institution_id=${institution_id}&channel=browser`,{}));
    }
	createUpdateRoleAccess(id,data) {
        return Promise.resolve(smartAttendence.post(`createUpdateRoleAccess/${id}?institution_id=${institution_id}&channel=browser`,data));
    }
	attendanceReport(id,data) {
        return Promise.resolve(smartAttendence.get(`attendanceReport/${id}?institution_id=${institution_id}&channel=browser${data}`));
    }
	paginationApiCall(url) {
        return Promise.resolve(smartAttendence.get(`${url}&institution_id=${institution_id}&channel=browser`));
    }

    postApiCall(url,data) {
        return Promise.resolve(smartAttendence.post(`${url}&institution_id=${institution_id}&channel=browser`,data));
    }
    getcountry() {
        return Promise.resolve(smartAttendence.get(`country?institution_id=${institution_id}&channel=browser`,));
    }
    getstate(id) {
        return Promise.resolve(smartAttendence.get(`state/${id}?institution_id=${institution_id}&channel=browser`,{}));
    }
    getcity(id) {
        return Promise.resolve(smartAttendence.get(`city/${id}?institution_id=${institution_id}&channel=browser`,{}));
    }
    manualNotification(id) {
        return Promise.resolve(smartAttendence.post(`sendMessageByJobManual/${id}?institution_id=${institution_id}&channel=browser`,{}));
    }
    getAttendenceDetail(id) {
        return Promise.resolve(smartAttendence.post(`getAttendenceDetail?location_id=${id}&institution_id=${institution_id}&channel=browser`,{}));
    }
    getGraphDetail(id) {
        return Promise.resolve(smartAttendence.post(`getGraphDetail?location_id=${id}&institution_id=${institution_id}&channel=browser`,{}));
    }
    getRecentData(id) {
        return Promise.resolve(smartAttendence.post(`getRecentData?location_id=${id}&institution_id=${institution_id}&channel=browser`,{}));
    }
    getAbsentData(id) {
        return Promise.resolve(smartAttendence.post(`getAbsentData?location_id=${id}&institution_id=${institution_id}&channel=browser`,{}));
    }
}

export default ConfigService;